import React from "react";
import { useSnackbar } from "notistack";

import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import { WeddingMediaUploadStatsDispatchContext } from "@@contexts/WeddingMediaUploadStatsContextsWrapper";
import { addWeddingMedia } from "@@services/wedding-media.service";
import { applyWeddingMediaLimits } from "@@utils";

function WeddingMediaUploadInput({ weddingId, ...restProps }, ref) {
  const { enqueueSnackbar } = useSnackbar();
  const wedding = React.useContext(CurrentWeddingContext);
  const uploadStatsDispatch = React.useContext(
    WeddingMediaUploadStatsDispatchContext
  );

  return (
    <input
      type="file"
      accept="image/*,video/*"
      multiple
      style={{ display: "none" }}
      onChange={handleUploadWeddingMediaInputChange}
      ref={ref}
      {...restProps}
    />
  );

  async function handleUploadWeddingMediaInputChange(e) {
    const files = Array.from(e.target.files);
    e.target.value = "";

    const { mediaFiles, limitsExceeded } = applyWeddingMediaLimits(
      files,
      wedding
    );
    if (limitsExceeded) {
      enqueueSnackbar(
        `This wedding is limited to ${wedding.limits.nPhotos} photos and ${wedding.limits.nVideos} videos. Uploads beyond these limits will be ignored ...`,
        { variant: "info" }
      );
    }
    if (mediaFiles.length === 0) return;
    try {
      await addWeddingMedia(weddingId, mediaFiles, {}, uploadStatsDispatch);
    } catch (e) {
      enqueueSnackbar(
        e.response?.data?.error ??
          `Some error occurred. Please retry the upload.`,
        { variant: "error" }
      );
      console.error(e);
    }
  }
}

export default React.forwardRef(WeddingMediaUploadInput);
